<template>
  <GrtMainWrapper>
    <div class="GrtSpace">
      <div class="GrtSpace__options">
        <router-link :to="{name: 'GrtGuaranteeNew'}">
          <button class="GrtSpace__options-new btn btn-primary">
            <span class="GrtSpace__options-new-plus">+</span>Добавить гарантию
          </button>
        </router-link>
      </div>
      <div class="GrtSpace__wrapper" v-if="this.GUARANTEES_LOAD">
        <GrtGuaranteePreviewHolder  v-for="grt in Array(4).keys()" :key="grt"/>
      </div>
      <div class="GrtSpace__title">
        Товары на гарантии <span class="GrtSpace__title-count">({{groupGuarantees.actives.length}})</span>
      </div>
      <GrtGuaranteePreview
        v-for="grt in groupGuarantees.actives"
        :key="grt.id"
        v-bind:guarantee="grt"
        @deleteGuaranteeById="deleteGuaranteeById"
      />

      <div class="GrtSpace__expire" v-if="groupGuarantees.inactives.length > 0">
        <div class="GrtSpace__title">
          Товары с истекшей гарантией <span class="GrtSpace__title-count">({{groupGuarantees.inactives.length}})</span>
        </div>
        <GrtGuaranteePreview
            v-for="grt in groupGuarantees.inactives"
            :key="grt.id"
            v-bind:guarantee="grt"
            @deleteGuaranteeById="deleteGuaranteeById"
        />
      </div>
      <GrtPaginator
          :count_items="this.GUARANTEES_COUNT"
          :current_page="this.current_page"
          :per_page="this.per_page"
          @changePage="changePage"
      />
    </div>
  </GrtMainWrapper>
</template>

<script>
import GrtGuaranteePreview from '../controls/GrtGuaranteePreview'
import {mapActions, mapGetters} from 'vuex'
import store from "@/vuex/store";
import GrtMainWrapper from "@/components/controls/GrtMainWrapper.vue";
import GrtPaginator from "@/components/controls/GrtPaginator.vue";
import GrtGuaranteePreviewHolder from "@/components/controls/GrtGuaranteePreviewHolder.vue";

export default {
  name: "GrtSpace",
  components: {GrtGuaranteePreviewHolder, GrtPaginator, GrtMainWrapper, GrtGuaranteePreview},
  data() {
    return {
      store: store,
      per_page: 16,
      current_page: 1,
    }
  },
  computed: {
    ...mapGetters([
        'GUARANTEES',
      'GUARANTEES_COUNT',
      'GUARANTEES_LOAD',
    ]),
    groupGuarantees: function () {
      let actives = [];
      let inactives = [];

      for (let i = 0; i < this.GUARANTEES.length; i++) {
        let g = this.GUARANTEES[i];
        if (g.expire_date >= (Date.now() / 1000)) {
          actives.push(g);
        } else {
          inactives.push(g);
        }
      }

      return {
        actives: actives,
        inactives: inactives
      }
    },
  },
  methods: {
    ...mapActions([
        'GET_GUARANTEES',
        'DELETE_GUARANTEE'
    ]),
    deleteGuaranteeById(data) {
      this.DELETE_GUARANTEE(data);
    },
    changePage(page) {
      this.GET_GUARANTEES({"per_page": this.per_page, "page": page});
      this.current_page = page;
    }
  },
  mounted() {
    if (this.$route.query.page) {
      this.current_page = Number(this.$route.query.page);
    }
    console.log("set");
    this.GET_GUARANTEES({"per_page": this.per_page, "page": this.current_page});
  },
}
</script>

<style lang="scss">
  @import "../../assets/styles/variables";

  .GrtSpace {
    &__options {
      display: flex;
      justify-content: right;
      gap: 20px;

      &-new {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        line-height: 32px;

        &-plus {
          font-size: 32px;
          line-height: 32px;
          font-weight: 900;
          margin-right: 8px;
        }
      }
    }
    &__title {
      margin: $padding-big 0 $padding-middle;
      font-size: 24px;

      &-count {
        color: $color-gray-light;
      }
    }

  }

  @media (max-width: $wrapper-avg-width) {
    .GrtSpace {
      &__title {
        margin: $padding-middle 0 $padding-small;
        font-size: 18px;
      }

      &__options {
        &-new {
          padding: 4px 12px;
          display: flex;
          justify-content: center;
          line-height: 24px;

          &-plus {
            display: none;
          }
        }
      }
    }
  }
</style>
