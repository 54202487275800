<template>
  <GrtMainWrapper>
    <div class="GrtEdit">
      <form class="GrtEdit__form" @submit.prevent="handleSubmit">
        <h3 class="page-title">Редактирование</h3>

        <div class="GrtEdit__form-input form-group">
          <label class="GrtEdit__form-input-label" for="titleInput">
            Название товара<span class="GrtEdit__form-input-label-required">*</span>
          </label>
          <input
              id="titleInput"
              class="GrtEdit__form-input form-control"
              type="text"
              placeholder="Чайник `Чудо-1`"
              v-model="current_guarantee.title"
              required/>
          <small
              class="GrtEdit__form-input-small form-text text-muted">
            Укажите название товара, например `<span
              class="GrtEdit__form-input-small-example">Apple iPhone Xr 64GB</span>`
          </small>
        </div>

        <div class="GrtEdit__form-input form-group">
          <label class="GrtEdit__form-input-label" for="placeInput">
            Место покупки<span class="GrtEdit__form-input-label-required">*</span>
          </label>
          <input
              id="placeInput"
              class="GrtEdit__form-input form-control"
              type="text"
              placeholder="Магазин быдовой техники, на ул. Автомобилистов 1"
              v-model="current_guarantee.place_title"
              required
              />
          <small class="GrtEdit__form-input-small form-text text-muted">
            Укажите место покупки товара
          </small>
        </div>

        <div class="GrtEdit__form-input form-group">
          <label class="GrtEdit__form-input-label" for="buyDateInput">
            Дата покупки<span class="GrtEdit__form-input-label-required">*</span>
          </label>
          <input
              id="buyDateInput"
              class="GrtEdit__form-input form-control"
              type="date"
              v-model="current_guarantee.buy_date"
              required/>
          <small
              id="buyDateHelp"
              class="GrtEdit__form-input-small form-text text-muted">
            {{ current_guarantee.buy_date }}Укажите точную даты покупки, с этой даты будет рассчитан гарантийный срок
          </small>
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label" for="buyDateInput">
            Цена покупки
          </label>
          <input
              id="priceInput"
              class="GrtNew__form-input form-control"
              type="number"
              v-model="current_guarantee.price"/>
          <small
              id="priceHelp"
              class="GrtNew__form-input-small form-text text-muted">
            Укажите цену покупки
          </small>
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label">
            Срок гарантии<span class="GrtEdit__form-input-label-required">*</span>
          </label>

          <div class="row">
            <div class="col-9">
              <input
                  id="expireInput"
                  class=" form-control"
                  type="number"
                  v-model="current_guarantee.expire_value"
                  min="0"
                required/>
            </div>
            <div class="col-3">
              <select
                  class="form-control"
                  aria-label="Select expire timeunit"
                  v-model="current_guarantee.expire_timeunit"
                  required>
                <option
                    v-for="period in periods"
                    :value="period.code"
                    :key="period.code"
                >
                  {{ period.description }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="GrtEdit__form-files form-group">
          <label>Фото чеков, гарантийных талонов</label>
          <div class="GrtEdit__images">
            <GrtFilePreview
                v-bind:file="f"
                v-for="f in current_guarantee.files"
                :key="f.id"
                @deleteImg="deleteImgFromList"
            />
          </div>
          <input
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
              @change="selectFile"
              multiple
          >
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label" for="noteInput">
            Комментарий
          </label>
          <textarea
              id="noteInput"
              class="GrtNew__form-input form-control"
              v-model="current_guarantee.note"
          />
        </div>
        
        <div class="GrtEdit__form-buttons form-group">
          <div class="btn btn-danger" @click="deleteGuarantee">Удалить</div>
          <div class="GrtEdit__form-buttons-right">
            <button type="submit" class="btn btn-primary">Сохранить</button>
            <button class="btn btn-secondary" @click="cancelCreate">Отмена</button>
          </div>
        </div>
      </form>
    </div>
  </GrtMainWrapper>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/router/router";
import axios from "@/utils/axios";
import {GetCookie} from "@/utils/cookie";
import GrtMainWrapper from "@/components/controls/GrtMainWrapper";
import GrtFilePreview from "@/components/controls/GrtFilePreview";

export default {
  name: "GrtGuaranteeEdit",
  props: {
    guarantee_id: {
      type: String,
      default: null
    }
  },
  components: {
    GrtFilePreview, GrtMainWrapper
  },
  methods: {
    ...mapGetters([
      'CURRENT_GUARANTEE',
      'PERIODS'
    ]),
    ...mapActions([
      'GET_GUARANTEE_BY_ID',
    ]),
    cancelCreate() {
      router.push('/space');
    },
    async deleteGuarantee() {
      await axios.get('guarantee.delete', {
        params: {
          guarantee_id: this.current_guarantee.id,
          auth_token: GetCookie(document, 'auth_token'),
        },
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        console.log(response.data);
        if (!response.data.response) {
          this.error_msg = response.data.error;
        }
        router.push('/space');
      }).catch((error) => {
        console.log(error);
      })
    },
    async deleteImgFromList(data) {
      let id = -1;
      for (let i = 0; i < this.current_guarantee.files.length; i++) {
        if (this.current_guarantee.files[i].id === data) {
          id = i;
        }
      }

      let resp = await axios.get('file.delete', {
        params: {
          file_id: this.current_guarantee.files[id].id
        },
        headers: {
          'Authentication-Token': GetCookie(document, 'auth_token')
        }
      })

      console.log(resp);

      this.current_guarantee.files.splice(id, 1);
    },
    async selectFile(event) {
      let formData = new FormData();

      for (let i = 0; i < event.target.files.length; i++) {
        formData.append('file-' + i.toString(), event.target.files[i]);
      }

      let resp = await axios.post('file.upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authentication-Token': GetCookie(document, 'auth_token')
        }
      })

      console.log(resp.data.response.files);

      for (let i = 0; i < resp.data.response.files.length; i++) {
        let item = resp.data.response.files[i];
        item.filename = event.target.files[i].name;
        this.current_guarantee.files.push(item);
      }
    },
    async handleSubmit() {
      let files = [];
      for (let i = 0; i < this.current_guarantee.files.length; i++) {
        files.push(this.current_guarantee.files[i].id)
      }

      let data = {
        guarantee_id: this.current_guarantee.id,
        title: this.current_guarantee.title,
        place_title: this.current_guarantee.place_title,
        buy_date: new Date(this.current_guarantee.buy_date).getTime() / 1000,
        expire_timeunit: this.current_guarantee.expire_timeunit,
        expire_value: this.current_guarantee.expire_value,
        price: this.current_guarantee.price,
        note: this.current_guarantee.note,
        auth_token: GetCookie(document, 'auth_token')
      };
      if (files.length > 0) 
        data.files = files.join(',');
      
      const payload = JSON.stringify(data, (k, v) => v ?? undefined);
      await axios('guarantee.upsert', {
        method: "POST",
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          'Authentication-Token': GetCookie(document, 'auth_token')
        }
      }).then((response) => {
        console.log(response.data);
        if (!response.data.response) {
          this.error_msg = response.data.error;
        }
        router.push('/space');
      }).catch((error) => {
        console.log(error);
      })
    }
  },
  computed: {
    current_guarantee: function () {
      return this.CURRENT_GUARANTEE();
    },
    periods: function () {
      return this.PERIODS();
    }
  },
  mounted() {
    this.GET_GUARANTEE_BY_ID(this.guarantee_id);
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

.GrtEdit {
  &__images {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    gap: 10px;
  }

  &__form {
    &-title {
      color: $color-black;
      margin-bottom: $padding-big;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin: $padding-big 0 $padding-big 0;
      align-items: center;

      &-right {
        display: flex;
        gap: $padding-small;
      }
    }

    &-input {
      &-label {
        &-required {
          color: red;
          margin-left: 5px;
        }
      }

      &-small {
        &-example {
          color: $color-primary;
        }
      }
    }
  }
}

</style>
