<template>
  <div class="GrtGuaranteePreviewHolder">
    <div class="GrtGuaranteePreviewHolder__wrapper">
      <div class="GrtGuaranteePreviewHolder__data">
        <div class="GrtGuaranteePreviewHolder__data-alias">
          <div class="GrtGuaranteePreviewHolder__data-alias-title">
          </div>
        </div>
        <div class="GrtGuaranteePreviewHolder__data-item">
          <div class="GrtGuaranteePreviewHolder__data-item-name"></div>
        </div>
        <div class="GrtGuaranteePreviewHolder__data-item">
          <div class="GrtGuaranteePreviewHolder__data-item-name"></div>
        </div>
        <div class="GrtGuaranteePreviewHolder__data-item">
          <div class="GrtGuaranteePreviewHolder__data-item-name"></div>
        </div>
      </div>
      <div class="GrtGuaranteePreviewHolder__data-files">
      </div>
    </div>
    <div class="GrtGuaranteePreviewHolder__options">
        <div class="GrtGuaranteePreviewHolder__edit"></div>
      <div class="GrtGuaranteePreviewHolder__delete"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "GrtGuaranteePreviewHolder",
}
</script>

<style lang="scss">
  @import "../../assets/styles/variables";

  @-webkit-keyframes AnimateBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes AnimateBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .GrtGuaranteePreviewHolder {
    width: 100%;
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background-size: 300% 300%;
    background-image: linear-gradient(120deg, #f3f3f3 0%, #f6f6f6 25%, #f8f8f8 51%, #fafafa 100%);
    -webkit-animation: AnimateBG 2s ease infinite;
    animation: AnimateBG 2s ease infinite;

    &__wrapper {
      display: grid;
      grid-template-columns: calc(100% - 60px - #{$padding-small}) 60px;
      grid-gap: $padding-small;
    }

    &__edit {
      width: 154px;
      height: 33px;
      background-color: #ededed;
      border-radius: 3px;
      margin-top: 10px;
    }

    &__delete {
      width: 80px;
      height: 18px;
      background-color: #ededed;
    }

    &__data {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &-item {
        display: flex;
        height: 16px;
        background-color: #ededed;
        width: 50%;
        margin-top: 8px;
      }
      &-files {
        background-color: #ededed;
        border-radius: 3px;
        width: 60px;
        height: 60px;
      }

      &-alias {
        display: flex;
        column-gap: 10px;

        &-title {
          width: 70%;
          height: 32px;
          background-color: #ededed;
          margin-bottom: 20px;
        }
      }
    }

    &__options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: $padding-middle;
    }
  }

  @media (max-width: $wrapper-avg-width) {
  .GrtGuaranteePreviewHolder {
    padding: $padding-small;

    &__edit {
      padding: 5px $padding-small;
    }
    &__data {
      &-alias {
        &-title {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
</style>