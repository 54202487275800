import {TimestampToDate} from "@/utils/time";

export function SetCookie(
    document,
    name,
    value,
    expire_date,
    path=null,
    domain=null,
    secure=true,
    same_site='Lax') {
    if (typeof expire_date === "number") {
        expire_date = TimestampToDate(expire_date);
    }

    const cookie = name + "=" + value +
        ((expire_date == null) ? "" : ("; expires=" + expire_date.toGMTString())) +
        ((path == null) ? "" : ("; path=" + path)) +
        ((domain == null) ? "" : ("; domain=" + domain)) +
        ((secure === true) ? "; secure" : "") +
        '; SameSite=' + same_site;
    document.cookie = cookie

}

function getCookieVal(document, offset) {
    let end_str = document.cookie.indexOf (";", offset);
    if (end_str === -1)
        end_str = document.cookie.length;
    return unescape(document.cookie.substring(offset, end_str));
}

export function GetCookie(document, name) {
    let arg = name + "=";
    let i = 0;
    while (i < document.cookie.length) {
        let j = i + arg.length;
        if (document.cookie.substring(i, j) === arg)
            return getCookieVal(document, j);
        i = document.cookie.indexOf(" ", i) + 1;
        if (i === 0) break;
    }
    return null;
}

export function DeleteCookie(document, name) {
    let exp = new Date();
    exp.setTime (exp.getTime() - 1);
    let value = GetCookie(document, name);
    document.cookie = name + "=" + value + "; expires=" + exp.toGMTString();
}

export default GetCookie;
