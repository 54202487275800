<template>
  <div class="GrtFilePreview">
    <div class="GrtFilePreview__base">
      <img class="GrtFilePreview__img" :src="this.imgUrl" alt="">
      <div>{{this.file.filename}}</div>
    </div>
    <div class="link-drop" @click="deleteImgClick">Удалить</div>
  </div>
</template>

<script>
export default {
  name: "GrtFilePreview",
  props: {
    file: {
      type: Object,
      default() {}
    }
  },
  methods: {
    deleteImgClick() {
      this.$emit('deleteImg', this.file.id)
    }
  },
  data () {
    return {}
  },
  computed: {
    imgUrl: function () {
      console.log(this.file.url);
      if (this.file.url.endsWith('.pdf')) {
        return require('../../assets/pdf.png');
      }
      return this.file.url;
    }
  }
}
</script>

<style lang="scss">

.GrtFilePreview {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__base {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__img {
    height: 60px;
    width: 60px;
    object-fit: cover;
  }
}

</style>