<template>
  <GrtMainWrapper>
    <div class="GrtRules">
      <div class="page-title">Пользовательское соглашение</div>
      <p>ghjhjk hjk hjk hjk</p>
    </div>
  </GrtMainWrapper>
</template>

<script>
import GrtMainWrapper from "@/components/controls/GrtMainWrapper.vue";

export default {
  name: "GrtRules",
  components: {GrtMainWrapper}
}
</script>

<style lang="scss">
@import "src/assets/styles/variables";

@media (max-width: $wrapper-avg-width) {
  .GrtRules {
    padding: 0 $padding-small;
  }
}
</style>
