<template>
  <GrtMainWrapper>
    <div class="GrtNew">
      <form class="GrtNew__form" @submit.prevent="handleSubmit">
        <h3 class="page-title">Добавление гарантии</h3>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label" for="titleInput">
            Название товара<span class="GrtNew__form-input-label-required">*</span>
          </label>
          <input
              id="titleInput"
              class="GrtNew__form-input form-control"
              type="text"
              placeholder="Чайник `Чудо-1`"
              v-model="title"
              required/>
          <small class="GrtNew__form-input-small form-text text-muted">
            Укажите название товара, например `<span
              class="GrtNew__form-input-small-example">Apple iPhone Xr 64GB</span>`
          </small>
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label" for="placeInput">
            Место покупки<span class="GrtNew__form-input-label-required">*</span>
          </label>
          <input
              id="placeInput"
              class="GrtNew__form-input form-control"
              type="text"
              placeholder="Магазин быдовой техники, на ул. Автомобилистов 1"
              v-model="place_title"
              required
          />
          <small class="GrtNew__form-input-small form-text text-muted">
            Укажите место покупки товара
          </small>
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label" for="buyDateInput">
            Дата покупки<span class="GrtNew__form-input-label-required">*</span>
          </label>
          <input
              id="buyDateInput"
              class="GrtNew__form-input form-control"
              type="date"
              v-model="buy_date"
              required/>
          <small
              id="buyDateHelp"
              class="GrtNew__form-input-small form-text text-muted">
            Укажите точную даты покупки, с этой даты будет рассчитан гарантийный срок
          </small>
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label" for="buyDateInput">
            Цена покупки
          </label>
          <input
              id="priceInput"
              class="GrtNew__form-input form-control"
              type="number"
              v-model="price"/>
          <small
              id="priceHelp"
              class="GrtNew__form-input-small form-text text-muted">
            Укажите цену покупки
          </small>
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label">
            Срок гарантии<span class="GrtNew__form-input-label-required">*</span>
          </label>

          <div class="row">
            <div class="col-9">
              <input
                  id="expireInput"
                  class=" form-control"
                  type="number"
                  v-model="expire_value"
                  min="0"
              required/>
            </div>
            <div class="col-3">
              <select
                  class="form-control"
                  aria-label="Select expire timeunit"
                  v-model="expire_timeunit"
                  required>
                <option
                    v-for="period in periods"
                    :value="period.code"
                    :key="period.code"
                >
                  {{ period.description }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="GrtNew__form-files form-group">
          <label>Фото чеков, гарантийных талонов</label>
          <div class="GrtNew__images">
            <GrtFilePreview
                v-bind:file="f"
                v-for="f in files"
                :key="f.id"
                @deleteImg="deleteImgFromList"
            />
          </div>
          <input
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
              @change="selectFile"
              multiple
          >
        </div>

        <div class="GrtNew__form-input form-group">
          <label class="GrtNew__form-input-label" for="noteInput">
            Комментарий
          </label>
          <textarea
              id="noteInput"
              class="GrtNew__form-input form-control"
              v-model="note"
          />
        </div>

        <div class="GrtNew__form-buttons form-group">
          <button class="btn btn-secondary" @click="cancelCreate">Отмена</button>
          <button type="submit" class="btn btn-primary">Добавить</button>
        </div>
      </form>
    </div>
  </GrtMainWrapper>
</template>

<script>
import axios from "@/utils/axios";
import {GetCookie} from "@/utils/cookie";
import GrtFilePreview from "@/components/controls/GrtFilePreview";
import router from "@/router/router";
import GrtMainWrapper from "../controls/GrtMainWrapper";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "GrtGuaranteeAdd",
  components: {
    GrtFilePreview, GrtMainWrapper
  },
  props: {
    guarantee: {
      type: Object,
      default() {
      }
    }
  },
  data() {
    return {
      title: null,
      place_title: null,
      buy_date: moment().format("YYYY-MM-DD"),
      currency: "RUB",
      expire_timeunit: "years",
      expire_value: null,
      files: []
    }
  },
  computed: {
    categories: function () {
      console.info("computed categories", this.CATEGORIES());
      return this.CATEGORIES();
    },
    currencies: function () {
      console.info("computed currencies", this.CURRENCIES());
      return this.CURRENCIES();
    },
    periods: function () {
      return this.PERIODS();
    }
  },
  methods: {
    ...mapGetters([
      'PERIODS',
      'CATEGORIES',
      'CURRENCIES'
    ]),
    ...mapActions([
      'GET_CATEGORIES',
      'GET_CURRENCIES'
    ]),
    cancelCreate() {
      router.push('/space');
    },
    async deleteImgFromList(data) {
      let id = -1;
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].id === data) {
          id = i;
        }
      }

      let resp = await axios.get('file.delete', {
        params: {
          file_id: this.files[id].id
        },
        headers: {
          'Authentication-Token': GetCookie(document, 'auth_token')
        }
      })

      console.log(resp);

      this.files.splice(id, 1);
    },
    async selectFile(event) {
      let formData = new FormData();

      for (let i = 0; i < event.target.files.length; i++) {
        formData.append('file-' + i.toString(), event.target.files[i]);
      }

      let resp = await axios.post('file.upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authentication-Token': GetCookie(document, 'auth_token')
        }
      })

      console.log(resp.data.response.files);

      for (let i = 0; i < resp.data.response.files.length; i++) {
        let item = resp.data.response.files[i];
        item.filename = event.target.files[i].name;
        this.files.push(item);
      }
    },
    async handleSubmit() {
      let files = undefined;
      for (let i = 0; i < this.files.length; i++) {
        files = files || []
        files.push(this.files[i].id)
      }

      const data = JSON.stringify({
        title: this.title,
        buy_date: new Date(this.buy_date).getTime() / 1000,
        place_title: this.place_title,
        expire_value: this.expire_value,
        return_value: this.return_value,
        expire_timeunit: this.expire_timeunit,
        return_timeunit: this.return_timeunit,
        files: files?.join(',') || undefined,
        note: this.note,
        price: this.price,
        currency: this.currency,
        category_id: this.category_id,
        auth_token: GetCookie(document, 'auth_token')
      })
      await axios('guarantee.upsert', {
        method: "POST",
        data: data,
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        console.log(response.data);
        if (!response.data.response) {
          this.error_msg = response.data.error;
        }
        router.push('/space');
      }).catch((error) => {
        console.log(error);
      })
    }
  },
  mounted() {
    this.GET_CATEGORIES();
    this.GET_CURRENCIES();
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

.GrtNew {
  .form-group {
    margin-bottom: 0.7rem;
  }

  &__images {
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    gap: 10px;
  }

  &__form {
    &-title {
      color: $color-black;
      margin-bottom: $padding-big;
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      gap: $padding-middle;
      margin: $padding-middle 0 0 0;
    }

    &-input {
      &-label {
        font-weight: 400;
        font-size: 16px;

        &-required {
          color: red;
          margin-left: 5px;
        }
      }

      &-small {
        font-size: 80%;
        font-weight: 300;
        line-height: 100%;
        padding-top: 4px;

        &-example {
          color: $color-primary;
        }
      }
    }
  }
}
</style>
