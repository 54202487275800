<template>
  <div class="GrtGuaranteePreview">
    <div class="GrtGuaranteePreview__wrapper">
      <div class="GrtGuaranteePreview__data">
        <div class="GrtGuaranteePreview__data-alias" :title="guarantee.title">
          <div class="GrtGuaranteePreview__data-alias-title">
            <div v-if="status" class="GrtGuaranteePreview__data-alias-status-active"></div>
            <div v-else class="GrtGuaranteePreview__data-alias-status"></div>
            {{guarantee.title}}
          </div>
        </div>
        <div class="GrtGuaranteePreview__data-item">
          <div class="GrtGuaranteePreview__data-item-name">Дата покупки: </div>
          <span class="GrtGuaranteePreview__data-item-value">{{buy_date_str}}</span>
        </div>
        <div class="GrtGuaranteePreview__data-item">
          <div class="GrtGuaranteePreview__data-item-name">Гарантия до: </div>
          <span class="GrtGuaranteePreview__data-item-value">{{expire_date_str}}</span>
        </div>
        <div class="GrtGuaranteePreview__data-item">
          <div class="GrtGuaranteePreview__data-item-name">Место покупки: </div>
          <span class="GrtGuaranteePreview__data-item-value">{{guarantee.place_title}}</span>
        </div>
      </div>
      <div class="GrtGuaranteePreview__data-files">
        <a v-for="f in guarantee.files" :key="f.id" :href="f.url">
          <img
            class="GrtGuaranteePreview__data-files-preview"
            :src="this.imgUrl(f)" alt="">
        </a>
      </div>
    </div>
    <div class="GrtGuaranteePreview__options">
      <router-link :to="{name: 'GrtGuaranteeEdit', params: {guarantee_id: guarantee.id}}">
        <button class="GrtGuaranteePreview__edit btn btn-primary">Редактировать</button>
      </router-link>
      <div class="link-drop" @click="deleteGuarantee">Удалить</div>
    </div>
  </div>
</template>

<script>
import {TimestampToDate} from "@/utils/time";

export default {
  name: "GrtGuaranteePreview",
  props: {
    guarantee: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    deleteGuarantee() {
      this.$emit('deleteGuaranteeById', this.guarantee.id)
    },
    imgUrl(file) {
      if (file.url.endsWith('.pdf')) {
        return require('../../assets/pdf.png');
      }
      return file.url;
    }
  },
  computed: {
    status: function () {
      if (!this.guarantee) {
        return false;
      }
      if (this.guarantee.expire_date >= (Date.now() / 1000))
      {
        return true;
      }
      return false;
    },
    buy_date_str: function () {
      return TimestampToDate(this.guarantee.buy_date).toISOString().slice(0,10)
    },
    expire_date_str: function () {
      return TimestampToDate(this.guarantee.expire_date).toISOString().slice(0,10)
    }
  }
}
</script>

<style lang="scss">
  @import "../../assets/styles/variables";

  .GrtGuaranteePreview {
    width: 100%;
    margin-top: 20px;
    background-color: $color-panel;
    padding: $padding-middle;
    border: $panel-border;
    border-radius: $panel-border-radius;

    &__wrapper {
      display: grid;
      grid-template-columns: calc(100% - 60px - #{$padding-small}) 60px;
      grid-gap: $padding-small;
    }

    &__edit {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 20px;
    }

    &__data {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &-files {
        max-width: 200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: $padding-small;

        &-preview {
          height: 60px;
          width: 60px;
          object-fit: cover;
          border-radius: $panel-border-radius;
        }
      }

      &-item {
        display: flex;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 24px;

        &-name {
          color: $color-black;
          font-weight: 100;
          margin-right: 5px;
        }

        &-value {
          font-size: 14px;
          color: $color-gray;
          font-weight: normal;
        }
      }

      &-alias {
        display: flex;
        column-gap: 10px;
        align-items: center;
        margin-bottom: $padding-middle;

        &-title {
          font-size: 18px;
          font-weight: 400;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-status {
          background-color: $color-gray;
          height: 12px;
          width: 12px;
          border-radius: 12px;
          float: left;
          margin: 5px 5px 0 0;

          &-active {
            background-color: #23ff09;
            height: 12px;
            width: 12px;
            border-radius: 12px;
            float: left;
            margin: 5px 5px 0 0;
          }
        }
      }
    }

    &__options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: $padding-middle;
    }
  }

  @media (max-width: $wrapper-avg-width) {
  .GrtGuaranteePreview {
    padding: $padding-small;

    &__edit {
      font-size: 12px;
      padding: 5px $padding-small;
    }
    &__data {
      &-alias {
        &-title {
          font-size: 16px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>