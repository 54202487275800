export const UNKNOWN_ERROR = {
    code: 'RequestErrors.Undefined',
    msg: 'Что-то пошло не так, повторите попытку позднее.',
    type: 'danger'
};

export const USER_NOT_FOUND_ERROR = {
    code: 'AuthErrors.UserNotFound',
    msg: 'Такой пользователь не найден.',
    type: 'danger'
};

export const RECOVERY_PASSWORD_TOKEN_INCORRECT = {
    code: 'AuthErrors.RecoveryPasswordTokenIncorrect',
    msg: 'Сессия измения пароля истекла.',
    type: 'danger'
};

export const USER_EMAIL_NOT_VERIFY_ERROR = {
    code: 'AuthErrors.UserEmailNotVerify',
    msg: 'Email не верифицирован.',
    type: 'danger'
};

export const USER_PHONE_NOT_VERIFY_ERROR = {
    code: 'AuthErrors.UserPhoneNotVerify',
    msg: 'Телефон не верифицирован.',
    type: 'danger'
};

export const USER_ALREADY_EXIST_ERROR = {
    code: 'AuthErrors.UserAlreadyExist',
    msg: 'Пользователь с таким email уже существует.',
    type: 'danger'
};

export const EMAIL_ALREADY_IS_VERIFY = {
    code: 'AuthErrors.EmailAlreadyIsVerify',
    msg: 'Email уже верифицирован.',
    type: 'info'
};

export const MANY_REQUESTS_BY_MINUTE = {
    code: 'TimeoutErrors.ManyRequestsByMinute',
    msg: 'Запрос можно отправлять не чаще одного раза в минуту.',
    type: 'danger'
};


export function errorProcess(current_error, errors) {
    if (!current_error) {
        return UNKNOWN_ERROR;
    }
    for (let i = 0; i < errors.length; i++){
        if (current_error.code === errors[i].code){
            return errors[i];
        }
    }
    return UNKNOWN_ERROR;
}

