<template>
  <div class="GrtLogout">
    Выход из системы...
  </div>
</template>

<script>
import {SetCookie} from "@/utils/cookie";
import router from "@/router/router";
import {mapActions} from "vuex";

export default {
  name: "GrtSignUp",
  methods: {
    ...mapActions([
        'UPDATE_AUTH_STATE'
    ])
  },
  mounted() {
    console.log('logout');
    SetCookie(document, 'auth_token', null, 0)
    this.UPDATE_AUTH_STATE(false);

    router.push('/sign_in');
  }
}
</script>

<style>

</style>
