<template>
  <nav>
    <ul class="pagination justify-content-center" >
      <li class="page-item">
        <router-link
            class="page-link"
            :to="{name: 'GrtSpace', query: { page: this.current_page - 1 }}"
            :class="{ disabled: (this.current_page === 1 || this.current_page > this.max_page + 1) }"
            @click="changePage(this.current_page - 1)"
        >
          Предыдущая
        </router-link>
      </li>
      <li class="page-item">
        <router-link
            class="page-link"
            :to="{name: 'GrtSpace', query: { page: 1 }}"
            :class="{ disabled: (this.current_page <= 1) }"
            @click="changePage(1)"
        >
          «
        </router-link>
      </li>
      <li v-for="page in this.pages_array" :key="page" :class="{ active: (page === this.current_page) }">
        <router-link
            class="page-link"
            :to="{name: 'GrtSpace', query: { page: page }}"
            @click="changePage(page)">
          {{ page }}
        </router-link>
      </li>
      <li class="page-item">
        <router-link
            class="page-link"
            :to="{name: 'GrtSpace', query: { page: this.max_page }}"
            :class="{ disabled: (this.current_page >= this.max_page) }"
            @click="changePage(this.max_page)"
        >
          »
        </router-link>
      </li>
      <li class="page-item">
        <router-link
            class="page-link"
            :to="{name: 'GrtSpace', query: { page: this.current_page + 1 }}"
            :class="{ disabled: (this.current_page >= this.max_page) }"
            @click="changePage(this.current_page + 1)"
        >
          Следующая
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>

export default {
  name: "GrtPaginator",
  props: {
    count_items: {
      type: Number,
      default: 0,
      required: true,
    },
    current_page: {
      type: Number,
      default: 1,
      required: true,
    },
    per_page: {
      type: Number,
      default: 2
    },
  },
  data() {
    return {
      page: this.current_page,
      pages: [],
    }
  },
  computed: {
    max_page() {
      return Math.ceil(this.count_items / this.per_page);
    },
    pages_array() {
      const min_page = 1;
      const max_count_pages = 5;
      let pages = [];

      if (this.current_page > this.max_page) {
        return pages;
      }

      let s = [-4, -3, -2, -1, 0, 1, 2, 3, 4];
      let k = [4, 5, 3, 6, 2, 7, 1, 8, 0];

      for (let i = 0; i < k.length; i++) {
        let number = this.current_page + s[k[i]]
        if (number >= min_page && number <= this.max_page && pages.length < 5) {
          pages.push(number)
        }
        if (pages.length >= max_count_pages) {
          break;
        }
      }
      return pages.sort();
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    }
  },
}
</script>

<style scoped lang="scss">

</style>