<template>
  <div class="GrtAuthOption">
    <div class="GrtAuthOption__wrapper">
      <div class="GrtAuthOption__wrapper-title">{{this.title}}</div>
      <div class="GrtAuthOption__wrapper-description">{{this.description}}</div>
    </div>
    <div class="GrtAuthOption__link">
      <router-link :to="{name: this.routeTo}">
        <button class="GrtAuthOption__link-button btn btn-success">{{this.buttonName}}</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "GrtAuthOption",
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    buttonName: {
      type: String,
      default: null
    },
    routeTo: {
      type: String,
      default: null
    },
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/variables";

.GrtAuthOption {
  background-color: #f9fbfb;
  border-radius: $panel-border-radius;
  border: solid #e2eaef 1px;
  padding: $padding-middle;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: $padding-middle;
  width: 100%;
  margin-bottom: $padding-middle;

  &__wrapper {
    width: 80%;

    &-title {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 100;
      color: $color-black;
    }

    &-description {
      font-size: 14px;
      color: $color-black;
      font-weight: 100;
    }
  }
  &__link{
    &-button {
      font-size: 14px;
      padding: 10px 30px;
    }
  }
}

</style>