<template>
  <div class="GrtDropmenu">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 34">
      <g data-name="Layer">
        <rect width="44" height="4"/>
        <rect y="15" width="44" height="4"/>
        <rect y="30" width="44" height="4"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "GrtDropmenu"
}
</script>

<style lang="scss">
.GrtDropmenu {
  height: 20px;
  width: 20px;
}

.GrtDropmenu:hover {
  cursor: pointer;
}
</style>