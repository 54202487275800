<template>
  <GrtHeader v-bind:user_name="user_name"/>
  <div class="App__wrapper">
    <router-view>
    </router-view>
  </div>
</template>

<script>
import GrtHeader from './components/controls/GrtHeader';


export default {
  name: 'App',
  components: {
    GrtHeader
  },
  data() {
    return {
      title: 'Main wrapper',
      user_name: null
    }
  },
}
</script>

<style lang="scss">
  @import "src/assets/styles/variables.scss";

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  .App{
    &__wrapper {
      max-width: $wrapper-max-width;
      min-width: $wrapper-min-width;
      margin: 100px auto $padding-big;
    }
  }

  @media (max-width: $wrapper-avg-width) {
    .App{
      &__wrapper {
        max-width: $wrapper-max-width;
        min-width: $wrapper-min-width;
        margin: 90px auto $padding-big;
      }
    }
  }

</style>
