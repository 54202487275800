import axios from "@/utils/axios";
import {GetCookie} from "@/utils/cookie";


async function authGET(uri, params) {
    return axios.get(uri, {
        params: params,
        headers: {
            'Authentication-Token': GetCookie(document, 'auth_token')
        }
    })
}

// eslint-disable-next-line no-unused-vars
async function authPOST(uri, formData) {
    return axios.post(uri, formData, {
        headers: {
            'Authentication-Token': GetCookie(document, 'auth_token')
        }
    })
}


export async function getCategories() {
    return authGET("category.getList")
}

export async function getCurrencies() {
    return authGET("meta.currencies")
}
