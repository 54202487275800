<template>
  <GrtMainWrapper>
    <div class="GrtCategoriesSettings">
      <form class="GrtCategoriesSettings__form" @submit.prevent="handleSubmit">
        <h3 class="page-title">Настройка уведомлений</h3>
        <div class="GrtCategoriesSettings__form-input form-group">
          <div class="GrtCategoriesSettings__form-alerts">
            <GrtAlert class="GrtCategoriesSettings__form-alerts-msg" :msg="this.alert_msg" :type="this.alert_type"/>
            <div class="GrtCategoriesSettings__form-alerts-loader spinner-grow text-primary" v-if="this.isLoading">
              <span class="sr-only"></span>
            </div>
          </div>
          <h5 class="GrtCategoriesSettings__form-title">Уведомления</h5>
          <div class="form-check">
            <input
                id="emailSendInput"
                class="form-check-input"
                type="checkbox"
                v-model="this.notifications.send_email"
            />
            <label class="GrtCategoriesSettings__form-input-label form-check-label" for="emailSendInput">
              Отправлять уведомления о истечении срока гарантии на почту
            </label>
          </div>
        </div>
        <div class="GrtCategoriesSettings__form-buttons form-group">
          <router-link :to="{name: 'GrtSpace'}">
            <button class="btn btn-secondary">Отмена</button>
          </router-link>
          <button type="submit" class="btn btn-primary">Сохранить</button>
        </div>
      </form>
    </div>
  </GrtMainWrapper>
</template>

<script>
import axios from "@/utils/axios";
import {errorProcess, USER_ALREADY_EXIST_ERROR} from "@/utils/errors";
import GrtAlert from "@/components/controls/GrtAllert";
import {GetCookie} from "@/utils/cookie";
import {mapActions, mapGetters} from "vuex";
import GrtMainWrapper from "@/components/controls/GrtMainWrapper.vue";

export default {
  name: "GrtCategoriesSettings",
  components: {
    GrtMainWrapper,
    GrtAlert
  },
  data() {
    return {
      alert_msg: null,
      alert_type: null,
      isLoading: false
    }
  },
  mounted() {
    this.GET_USER_INFO();
  },
  computed: {
    notifications: function () {
      return this.USER_NOTIFICATION();
    }
  },
  methods: {
    ...mapGetters([
      'USER_NOTIFICATION'
    ]),
    ...mapActions([
      'GET_USER_INFO'
    ]),
    async handleSubmit() {
      this.alert_msg = null;
      this.isLoading = true;

      let notifications = [];

      if (this.notifications.send_email) {
        notifications.push('send_email');
      }

      await axios('user.changeNotification', {
        method: "POST",
        data: JSON.stringify({
          notifications: notifications.join(','),
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authentication-Token': GetCookie(document, 'auth_token')
        }
      }).then((response) => {
        this.isLoading = false;
        const data = response.data;
        if (data.response) {
          this.alert_type = 'info';
          this.alert_msg = 'Настройки изменены';
        } else {
          const error = errorProcess(
              data.error,
              [USER_ALREADY_EXIST_ERROR]
          );
          this.alert_msg = error.msg;
          this.alert_type = error.type;
        }
      }).catch((error) => {
        console.log(error);
        this.isLoading = false;
        const error_ = errorProcess(null, null);
        this.alert_msg = error_.msg;
        this.alert_type = error_.type;
      })
    }
  },
}
</script>

<style lang="scss">

@import "../../assets/styles/variables";

.GrtCategoriesSettings {
  &__form {
    &-buttons {
      display: flex;
      justify-content: space-between;
      margin: $padding-big 0 $padding-big 0;
      align-items: center;

      &-right {
        display: flex;
        gap: $padding-small;
      }
    }

    &-alerts {
      display: flex;
      justify-content: center;
      margin-bottom: $padding-small;
      flex-wrap: wrap;

      &-loader {
      }

      &-msg {
        width: 100%;
        font-size: 16px;
        font-weight: 100;
      }
    }

  }
}

</style>
