<template>
  <div class="GrtMainWrapper">
    <slot>

    </slot>
  </div>
</template>

<script>

import axios from "@/utils/axios";
import GetCookie, {SetCookie} from "@/utils/cookie";
import {mapActions} from "vuex";
import router from "@/router/router";

export default {
  name: "GrtMainWrapper",
  components: {},
  props: {},
  data() {
    return {
      title: 'Main wrapper',
    }
  },
  computed: {},
  methods: {
    ...mapActions([
      'UPDATE_AUTH_STATE'
    ]),
  },
  watch: {},
  mounted() {
    const unlogPages = ['/sign_in', '/sign_up', '/', ''];
    const redirectToSpacePageIfAuth = [
      '/sign_in',
      '/sign_up',
      '/recovery_password',
      '/confirm_recovery_password',
      '/resend_email_confirm',
      '/email_confirm',
      "/",
    ];
    const token = GetCookie(document, 'auth_token');
    const currentPath = router.currentRoute._value.fullPath;

    if (router.currentRoute._value.fullPath === '/logout') {
      SetCookie(document, 'auth_token', null, 0);
      return;
    }
    console.log(unlogPages);
    console.log(router.currentRoute._value.fullPath);

    if (!token && unlogPages.includes(router.currentRoute._value.fullPath)) {
      return;
    }

    axios.get('auth.refresh', {
      params: {auth_token: token}
    }).then((response) => {
      console.log(response.data.error);
      console.log(response.data.code);

      if (response.data.error) {
        console.log(response.data.error)
        SetCookie(document, 'auth_token', null, 0);
        this.UPDATE_AUTH_STATE(false);
        router.push('/sign_in');
        return;
      }
      let auth = response.data.response.auth;

      if (!auth) {
        this.UPDATE_AUTH_STATE(false);
        router.push('/sign_in');
        return;
      }

      SetCookie(document, 'auth_token', auth.token, auth.expire);
      this.UPDATE_AUTH_STATE(true);

      if (redirectToSpacePageIfAuth.includes(currentPath)) {
        router.push('/space');
      }
    })
  },
  async created() {
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

.GrtMainWrapper {
  padding: 0 $padding-middle;
}

@media (max-width: $wrapper-avg-width) {
  .GrtMainWrapper {
    padding: 0 $padding-small;
  }
}
</style>
