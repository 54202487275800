<template>
  <GrtMainWrapper>
  <div class="GrtRecoveryPassword">
    <form class="GrtRecoveryPassword__form" @submit.prevent="handleSubmit">
      <div class="page-title">Восстановление пароля</div>
      <div class="GrtRecoveryPassword__form-alerts">
        <GrtAlert class="GrtRecoveryPassword__form-alerts-msg" :msg="this.alert_msg" :type="this.alert_type"/>
        <div class="GrtRecoveryPassword__form-alerts-loader spinner-grow text-primary" v-if="this.isLoading">
          <span class="sr-only">Регистрация...</span>
        </div>
      </div>
      <div class="form-group">
        <label for="emailInput">Ваш Email-адрес</label>
        <input
            id="emailInput"
            class="GrtRecoveryPassword__form-input form-control"
            type="email"
            placeholder="email"
            v-model="email"
            required>
        <small id="emailHelp" class="form-text text-muted">Введите Email-адрес указанный при регистрации</small>
      </div>
      <button type="submit" class="btn btn-primary GrtRecoveryPassword__form-submit">Восстановить</button>
    </form>
  </div>
  </GrtMainWrapper>
</template>

<script>
import axios from "@/utils/axios";
import {
  errorProcess,
  USER_NOT_FOUND_ERROR,
} from "@/utils/errors";
import GrtAlert from "@/components/controls/GrtAllert";
import GrtMainWrapper from "@/components/controls/GrtMainWrapper.vue";


export default {
  name: "GrtRecoveryPassword",
  data() {
    return {
      email: null,
      password: null,
      alert_msg: null,
      alert_type: null,
      isLoading: false
    }
  },
  components: {
    GrtMainWrapper,
    GrtAlert
  },
  methods: {
    async handleSubmit () {
      this.alert_msg = null;
      this.isLoading = true;

      await axios('auth.passwordRecovery', {
        method: "POST",
        data: JSON.stringify({
          email: this.email,
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        this.isLoading = false;
        const data = response.data;

        if (data.response) {
          this.alert_msg = 'На указанный email было отправлено письмо с инструкцией по восстановлению пароля.'
          this.alert_type = 'info';

        } else {
          const error = errorProcess(
              data.error,
              [
                USER_NOT_FOUND_ERROR,
              ]
          )
          this.alert_msg = error.msg;
          this.alert_type = error.type;
        }
      }).catch((error) => {
        console.log(error);
        this.isLoading = false;
        const _error = errorProcess(null, null)
        this.alert_msg = _error.msg;
        this.alert_type = _error.type;
      })
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

.GrtRecoveryPassword {
  &__form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 10% auto;

    &-alerts {
      display: flex;
      justify-content: center;
      margin-bottom: $padding-small;

      &-loader {
      }

      &-msg {
        width: 100%;
        font-size: 16px;
        font-weight: 100;
      }
    }

    &-submit {
      margin: 20px auto 0;
     }

    &-info {
      font-size: 14px;
      text-align: right;
    }
  }
}

@media (max-width: $wrapper-avg-width) {
  .GrtRecoveryPassword {
    padding: 0 $padding-small;
  }
}
</style>
