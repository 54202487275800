<template>
  <div class="GrtHeader">
    <div class="GrtHeader__wrapper">
      <router-link class="GrtHeader__wrapper-logo" :to="{name: 'GrtIndex'}">
        <GrtLogo/>
      </router-link>
      <div class="GrtHeader__wrapper-menu">
        <div v-if="this.status === false" class="GrtHeader__wrapper-menu-group">
          <router-link :to="{name: 'GrtSignIn'}">
            <div class="GrtHeader__wrapper-menu-link">Войти</div>
          </router-link>
        </div>
        <div v-else class="GrtHeader__wrapper-menu-group">
          <router-link :to="{name: 'GrtSpace'}">
            <div class="GrtHeader__wrapper-menu-link">Гарантии</div>
          </router-link>
          <div class="btn-group">
            <div class="GrtHeader__wrapper-menu-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Профиль</div>
            <div class="dropdown-menu">
              <router-link :to="{name: 'GrtSettings'}">
                <div class="GrtHeader__wrapper-menu-link dropdown-item">Настройка уведомлений</div>
              </router-link>
              <div class="dropdown-divider"></div>
              <router-link :to="{name: 'GrtLogout'}">
                <div class="GrtHeader__wrapper-menu-link dropdown-item">Выйти</div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="GrtHeader__wrapper-dropmenu">
        <div class="btn-group">
          <GrtDropmenu class="GrtHeader__wrapper-menu-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
          <div class="dropdown-menu">
            <router-link :to="{name: 'GrtSpace'}">
              <div class="GrtHeader__wrapper-menu-link dropdown-item">Мои гарантии</div>
            </router-link>
            <router-link :to="{name: 'GrtCategoriesSettings'}">
              <div class="GrtHeader__wrapper-menu-link dropdown-item">Категории</div>
            </router-link>
            <router-link :to="{name: 'GrtSettings'}">
              <div class="GrtHeader__wrapper-menu-link dropdown-item">Настройка уведомлений</div>
            </router-link>
            <div class="dropdown-divider"></div>
            <router-link :to="{name: 'GrtLogout'}">
              <div class="GrtHeader__wrapper-menu-link dropdown-item">Выйти</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GrtLogo from '../svg/GrtLogo';
import {mapActions, mapGetters} from "vuex";
import GrtDropmenu from "@/components/svg/GrtDropmenu";

export default {
  name: "GrtHeader",
  methods: {
    ...mapGetters([
      'AUTH_STATUS'
    ]),
    ...mapActions([
      'UPDATE_AUTH_STATE'
    ])
  },
  props: {
    user_name: {
      type: String,
      default: null
    }
  },
  components: {
    GrtLogo,
    GrtDropmenu
  },
  computed: {
    status: function () {
      return this.AUTH_STATUS();
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    this.UPDATE_AUTH_STATE(false);
  }
}
</script>

<style lang="scss">
  @import "../../assets/styles/variables";

  .GrtHeader {
    background-color: $color-white;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    height: 60px;
    position: fixed;
    transition: all 0.5s;
    top: 0;
    width: 100%;
    z-index: 999;

    &__wrapper {
      margin: 0 auto;
      max-width: $wrapper-max-width;
      min-width: $wrapper-min-width;
      padding: 0 20px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-dropmenu {
        display: none;
      }

      &-menu {
        &-group {
          display: inline-flex;
          gap: $padding-middle;
        }

        &-link {
          color: $color-black;
          font-family: sans-serif;
          font-weight: lighter;
        }
        &-link:hover {
          color: $color-primary;
          cursor: pointer;
        }
        &-link:active {
          color: $color-white;
        }
      }
    }
  }

  @media (max-width: $wrapper-avg-width) {
    .GrtHeader__wrapper-menu {
      display: none;
    }
    .GrtHeader__wrapper-dropmenu {
      display: flex;
    }
  }

</style>
