import axios from "@/utils/axios";
import {createStore} from 'vuex';
import {GetCookie} from "@/utils/cookie";
import {TimestampToDate} from "@/utils/time";
import {getCategories} from "@/api";
import {getCurrencies} from "@/api";


const store = createStore({
  state: {
    periods: [
      {
        code: 'forever',
        description: 'Бессрочно'
      },
      {
        code: 'years',
        description: 'Год(а)/лет'
      },
      {
        code: 'months',
        description: 'Месяц(ев)'
      },
      {
        code: 'days',
        description: 'Дней'
      }
    ],
    guarantees: [],
    categories: [],
    currencies: [],
    current_guarantee: {},
    count_guarantees: 0,
    count_categories: 0,
    count_currencies: 0,
    auth_status: false,
    guarantees_is_loading: false,
    user_info: {},
    notifications: {},
  },
  mutations: {
    SET_GUARANTEES_LOAD: (state, status) => {
      state.guarantees_is_loading = status;
    },
    SET_GUARANTEES: (state, guarantees_meta) => {
      state.guarantees = guarantees_meta.guarantees;
      state.count_guarantees = guarantees_meta.count;
    },
    SET_CATEGORIES: (state, categories_meta) => {
      state.categories = categories_meta.categories;
      state.count_categories = categories_meta.count;
    },
    SET_CURRENCIES: (state, currencies_meta) => {
      state.currencies = currencies_meta.currencies;
      state.count_currencies = currencies_meta.count;
    },
    SET_CURRENT_GUARANTEE: (state, guarantee) => {
      guarantee.buy_date = TimestampToDate(guarantee.buy_date).toISOString().slice(0, 10);
      guarantee.expire_date = TimestampToDate(guarantee.expire_date).toISOString().slice(0, 10);
      state.current_guarantee = guarantee;
    },
    SET_AUTH: (state, status) => {
      state.auth_status = status;
    },
    SET_USER_INFO: (state, user_info) => {
      let notifications = user_info.notifications;
      if (notifications.indexOf('send_email') !== -1) {
        state.notifications.send_email = true;
      }
    },
    DROP_GUARANTEE: (state, drop_index) => {
      if (drop_index >= 0) {
        state.guarantees.splice(drop_index, 1);
        state.count_guarantees--;
      }
    }
  },
  actions: {
    GET_GUARANTEE_BY_ID({commit}, guarantee_id) {
      return axios('guarantee.get', {
        method: "GET",
        params: {
          auth_token: GetCookie(document, 'auth_token'),
          guarantee_id: guarantee_id
        }
      }).then((guarantees) => {
        let data = guarantees.data;
        if (data.error) {
          console.log(guarantees.data);
          return null
        } else {
          let guarantee = data.response.guarantee;
          commit('SET_CURRENT_GUARANTEE', guarantee);
          return guarantee;
        }
      }).catch((error) => {
        console.log(error);
      })
    },
    GET_USER_INFO({commit}) {
      return axios('user.info', {
        method: "GET",
        params: {
          auth_token: GetCookie(document, 'auth_token'),
        }
      }).then((response) => {
        let data = response.data;
        if (data.error) {
          console.log(data);
          return null
        } else {
          let user_info = data.response.user;
          commit('SET_USER_INFO', user_info);
          return user_info;
        }
      }).catch((error) => {
        console.log(error);
      })
    },
    GET_GUARANTEES({commit}) {
      return axios('guarantee.getList', {
        method: "GET",
        params: {
          auth_token: GetCookie(document, 'auth_token')
        }
      }).then((guarantees) => {
        let data = guarantees.data;
        if (data.error) {
          console.log(guarantees.data);
          return [];
        } else {
          commit('SET_GUARANTEES', data.response);
          return guarantees;
        }

      }).catch((error) => {
        console.log(error);
      })
    },
    GET_CATEGORIES({commit}) {
      getCategories().then((response) => {
        const data = response.data
        if (data.error) {
          console.log(response.data)
          commit('SET_CATEGORIES', [])
          return []
        } else {
          console.info("SET_CATEGORIES", data.response)
          commit('SET_CATEGORIES', data.response)
          return response
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    GET_CURRENCIES({commit}) {
      getCurrencies().then((response) => {
        const data = response.data
        if (data.error) {
          console.log(response.data)
          commit('SET_CURRENCIES', [])
          return []
        } else {
          console.info("SET_CURRENCIES", data.response)
          commit('SET_CURRENCIES', data.response)
          return response
        }
      })
    },
    async UPDATE_AUTH_STATE({commit}, status) {
      await commit('SET_AUTH', status);
    },
    async DELETE_GUARANTEE({commit}, id) {
      let drop_index = -1;

      for (let i = 0; i < this.state.guarantees.length; i++) {
        if (this.state.guarantees[i].id === id) {
          drop_index = i;
          break;
        }
      }

      if (drop_index < 0) {
        return;
      }

      let guarantee = this.state.guarantees[drop_index];

      let resp = await axios.get('guarantee.delete', {
        params: {
          auth_token: GetCookie(document, 'auth_token'),
          guarantee_id: guarantee.id
        }
      });

      console.log(resp);
      commit('DROP_GUARANTEE', drop_index);
    }
  },
  getters: {
    PERIODS(state) {
      return state.periods;
    },
    GUARANTEES(state) {
      return state.guarantees;
    },
    CATEGORIES(state) {
      return state.categories;
    },
    CURRENCIES(state) {
      return state.currencies;
    },
    GUARANTEES_COUNT(state) {
      return state.count_guarantees;
    },
    AUTH_STATUS(state) {
      console.log('AUTH_STATUS');
      console.log(state.auth_status);
      return state.auth_status
    },
    CURRENT_GUARANTEE(state) {
      return state.current_guarantee
    },
    USER_NOTIFICATION(state) {
      return state.notifications
    },
    GUARANTEES_LOAD(state) {
      return state.guarantees_is_loading
    }
  }
});

export default store;
