<template>
  <div class="alert alert-danger" role="alert" v-if="this.msg && this.type === 'danger'">
    {{this.msg}}
  </div>
  <div class="alert alert-warning" role="alert" v-else-if="this.msg & this.type === 'warning'">
    {{this.msg}}
  </div>
  <div class="alert alert-info" role="alert" v-else-if="this.msg">
    {{this.msg}}
  </div>
</template>

<script>
export default {
  name: "GrtAlert",
  props: {
    msg: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'danger',
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style>

</style>