<template>
  <GrtMainWrapper>
    <div class="GrtIndex">
      <h1 class="GrtIndex__title">Ваши гарантии больше не потеряются</h1>
      <h5 class="GrtIndex__subtitle">Храните все гарантии вместе, они напомнят о себе когда гарантийный срок будет
        заканчивтаься</h5>
      <div class="GrtIndex__action">
        <router-link :to="{name: 'GrtSignUp'}">
          <button class="GrtIndex__action-signup btn btn-primary">Зарегистрируйтесь бесплатно</button>
        </router-link>
        <router-link :to="{name: 'GrtSignIn'}">
          <div class="GrtIndex__action-signin">Уже есть аккаунт? Войдите</div>
        </router-link>
      </div>
      <div class="GrtIndex__featured">
        <img class="GrtIndex__featured-img" :src="require('../../assets/re.png')" alt="">
        <div class="GrtIndex__featured-article">
          <div class="GrtIndex__featured-article-item">
            <div class="GrtIndex__featured-article-item-title">ГАРАНТИИ НА ВСЕ ТОВАРЫ В ОДНОМ МЕСТЕ</div>
            <div class="GrtIndex__featured-article-item-text">После покупки просто добавьте информацию о товар и чеки,
              они будут храниться здесь
            </div>
          </div>
          <div class="GrtIndex__featured-article-item">
            <div class="GrtIndex__featured-article-item-title">КОГДА ГАРАНТИЯ ЗАКАНЧИВАЕТСЯ ВЫ ПОЛУЧИТЕ УВЕДОМЛЕНИЯ
            </div>
            <div class="GrtIndex__featured-article-item-text">После покупки просто добавьте информацию о товар и чеки,
              они будут храниться здесь
            </div>
          </div>
          <div class="GrtIndex__featured-article-item">
            <div class="GrtIndex__featured-article-item-title">ХРАНИТЕ ЧЕКИ ДЛЯ ПРОСТОТЫ ГАРАНТИЙНОГО ОБСЛУЖИВАНИЯ</div>
            <div class="GrtIndex__featured-article-item-text">Электронные чеки, гарантийные талоны, фотокопии чеков, всё
              это поможет вам быстро провести гарайтиное обслуживание
            </div>
          </div>
        </div>
      </div>
      <div class="GrtIndex__info">
        <h2 class="GrtIndex__subtitle">Что полезно знать о гарайтийном обслуживании</h2>
        <p class="GrtIndex__info-paragraph">В соответствии с п. 5 ст. 18 Закона Российской Федерации от 7 февраля 1992
          г. № 2300-1 «О защите прав потребителей» отсутствие у потребителя кассового или товарного чека либо иного
          документа, удостоверяющих факт и условия покупки товара, не является основанием для отказа в удовлетворении
          его требований.</p>
        <p class="GrtIndex__info-paragraph">Согласно ст. 493 Гражданского Кодекса Российской Федерации помимо указанных
          документов к документам, которые подтверждают факт заключения договора, можно отнести также и другие
          документы, подтверждающие оплату товара. К таким документам может быть отнесена эксплуатационная документация,
          в которой сделана отметка об оплате. Соответственно, вместо товарного или кассового чека потребителем в
          подтверждение факта заключения договора могут быть представлены и другие документы, подтверждающие факт оплаты
          товара. Кроме того, согласно ст. 493 Гражданского Кодекса Российской Федерации отсутствие у потребителя
          указанных документов не лишает возможности потребителя ссылаться на свидетельские показания, которые
          подтверждают факт заключения договора и его условий.</p>
      </div>
    </div>
  </GrtMainWrapper>
</template>

<script>
import GrtMainWrapper from "@/components/controls/GrtMainWrapper";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GrtIndex",
  components: {GrtMainWrapper},
  methods: {
    ...mapGetters([
      'AUTH_STATUS'
    ]),
    ...mapActions([
      'UPDATE_AUTH_STATE'
    ]),
  },
}

</script>

<style lang="scss">
  @import "../../assets/styles/variables";

  .GrtIndex {
    padding-top: $padding-middle;

    &__title {
      color: $color-black;
      text-align: center;
    }

    &__subtitle {
      color: $color-black;
      font-size: 24px;
      font-weight: lighter;
      line-height: 150%;
      text-align: center;
    }

    &__action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 40px auto;
      width: max-content;

      &-signup {
        font-size: 16px;
        padding: 16px 80px;
        text-align: center;
        font-weight: lighter;
      }

      &-signin {
        text-align: center;
        margin-top: 20px;
        font-weight: 100;
        text-decoration-line: underline;
        color: black;
        font-size: 18px;
      }
    }

    &__info {
      margin-top: $padding-big;
      &-paragraph {
        font-size: 14px;
        font-weight: 100;
        color: $color-black;
      }
    }

    &__featured {
      display: inline-flex;
      margin-top: $padding-big;

      &-img {
        width: 100%;
        object-fit: contain;
        margin-right: 10px;
      }
      &-article {
        &-item {
          margin-bottom: $padding-middle;

          &-title {
            font-size: 18px;
            font-weight: lighter;
            line-height: 28px;
            padding-bottom: 10px;
          }
          &-text {
            font-size: 16px;
            line-height: 150%;
            font-weight: lighter;
          }
        }
      }
    }
  }

  @media (max-width: $wrapper-avg-width) {
    .GrtIndex__title {
      font-size: 30px;
      line-height: 40px;
    }

    .GrtIndex__subtitle {
      font-size: 18px;
      line-height: 150%;
    }

    .GrtIndex__action-signup {
      padding: 16px 20px;
    }
    .GrtIndex__action-signin {
      font-size: 16px;
    }
    .GrtIndex__featured {
      display: flex;
      flex-direction: column;
      margin-top: $padding-big;

      &-article {
        &-item {
          &-title {
            text-align: center;
          }
          &-text {
            text-align: center;
          }
        }
      }

      &-img {
        width: 100%;
        margin-bottom: $padding-middle;
      }
    }
  }
</style>
