<template>
  <GrtMainWrapper>
    <div class="GrtConfirmEmail">
      <form class="GrtConfirmEmail__form" @submit.prevent="handleSubmit">
        <div class="GrtConfirmEmail__form-alerts">
          <GrtAlert class="GrtConfirmEmail__form-alerts-msg" :msg="this.alert_msg" :type="this.alert_type"/>
          <div class="GrtConfirmEmail__form-alerts-loader spinner-grow text-primary" v-if="this.isLoading">
            <span class="sr-only">Обработка...</span>
          </div>
        </div>
      </form>
    </div>
  </GrtMainWrapper>
</template>

<script>
import axios from "@/utils/axios";
import {
  errorProcess,
} from "@/utils/errors";
import GrtAlert from "@/components/controls/GrtAllert";
import router from "@/router/router";
import {SetCookie} from "@/utils/cookie";
import {mapActions} from "vuex";
import GrtMainWrapper from "@/components/controls/GrtMainWrapper.vue";


export default {
  name: "GrtConfirmEmail",
  data() {
    return {
      email: null,
      password: null,
      alert_msg: null,
      alert_type: null,
      isLoading: false
    }
  },
  components: {
    GrtMainWrapper,
    GrtAlert
  },
  mounted() {
    this.alert_msg = null;
    this.isLoading = true;

    const token = this.$route.query.cc

    if (!token) {
      router.push('/sign_in')
    }

    axios('auth.confirm', {
      method: "POST",
      data: JSON.stringify({
        token: token,
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response) => {
      this.isLoading = false;
      const data = response.data;

      if (data.response) {
        let auth = response.data.response.auth;

        SetCookie(document,'auth_token', auth.token, auth.expire);
        this.UPDATE_AUTH_STATE(true);
        router.push('/space')
      } else {
        const error = errorProcess(
            data.error,
            [
            ]
        )
        this.alert_msg = error.msg;
        this.alert_type = error.type;
      }
    }).catch((error) => {
      console.log(error);
      this.isLoading = false;
      const error_ = errorProcess(null, null)
      this.alert_msg = error_.msg;
      this.alert_type = error_.type;

    })
  },
  methods: {
    ...mapActions([
      'UPDATE_AUTH_STATE'
    ]),
  }
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

.GrtConfirmEmail {
  &__form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 10% auto;

    &-alerts {
      display: flex;
      justify-content: center;
      margin-bottom: $padding-small;

      &-loader {
      }

      &-msg {
        width: 100%;
        font-size: 16px;
        font-weight: 100;
      }
    }

    &-submit {
      margin: 20px auto 0;
    }

    &-info {
      font-size: 14px;
      text-align: right;
    }
  }
}

</style>
