<template>
  <div class="GrtSignIn">
    <form class="GrtSignIn__form" @submit.prevent="handleSubmit">
      <div class="GrtSignIn__form-title page-title">Авторизация</div>

      <div class="GrtSignIn__form-alerts">
        <GrtAlert class="GrtSignIn__form-alerts-msg" :msg="this.alert_msg" :type="this.alert_type"/>
        <div class="GrtSignIn__form-alerts-loader spinner-grow text-primary" v-if="this.isLoading">
          <span class="sr-only">Вход...</span>
        </div>
      </div>
      <div class="GrtSignIn__form-group form-group">
        <label class="GrtSignIn__form-group-label" for="emailInput">Email-адрес</label>
        <input id="emailInput" class="GrtSignIn__form-group-input form-control" type="email" placeholder="email" v-model="email" required>
      </div>
      <div class="GrtSignIn__form-group form-group">
        <label class="GrtSignIn__form-group-label" for="passwordInput">Пароль</label>
        <input id="passwordInput" class="GrtSignIn__form-input form-control" type="password" placeholder="пароль" v-model="password" required>
      </div>
      <button type="submit" class="GrtSignIn__form-submit btn btn-primary">Войти</button>
      <router-link :to="{name: 'GrtSignUp'}">
        <div class="GrtSignIn__form-signup">Нет аккаунта? Зарегистрируйтесь</div>
      </router-link>
    </form>

    <div class="GrtSignIn__form-options">
      <GrtAuthOption
          title="Восстановление пароля"
          routeTo="GrtRecoveryPassword"
          buttonName="Восстановить"
          description="Забыли пароль? Вы всегда можете его восстановить, использовав свой email."
      />
      <GrtAuthOption
          title="Верификация email"
          routeTo="GrtResendConfirmEmail"
          buttonName="Верефицировать"
          description="После регистрации не пришло письмо на email? Отправьте письмо повторно, и подтвердите почту."
      />
    </div>

  </div>
</template>

<script>
import axios from "axios";
import {SetCookie} from "@/utils/cookie";
import router from "@/router/router";
import {mapActions} from "vuex";
import {
  errorProcess,
  USER_NOT_FOUND_ERROR,
  USER_EMAIL_NOT_VERIFY_ERROR,
  USER_PHONE_NOT_VERIFY_ERROR,
} from "@/utils/errors";
import GrtAuthOption from "@/components/controls/GrtAuthOption";
import GrtAlert from "@/components/controls/GrtAllert";


export default {
  name: "GrtSignIn",
  components: {
    GrtAuthOption,
    GrtAlert
  },
  methods: {
    ...mapActions([
      'UPDATE_AUTH_STATE'
    ]),
    async handleSubmit () {
      this.alert_msg = null;
      this.isLoading = true;

      let data = JSON.stringify({
        email: this.email,
        password: this.password,
      });
      await axios('auth.login', {
        method: "POST",
        data: data,
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        const data = response.data;

        if (data.response) {
          const auth = data.response.auth;
          SetCookie(document,'auth_token', auth.token, auth.expire);
          this.UPDATE_AUTH_STATE(true);
          router.push('/space');
        } else {
          this.isLoading = false;
          const error = errorProcess(
              data.error,
              [
                USER_NOT_FOUND_ERROR,
                USER_EMAIL_NOT_VERIFY_ERROR,
                USER_PHONE_NOT_VERIFY_ERROR,
              ]
          );
          this.alert_msg = error.msg;
          this.alert_type = error.type;
        }
      }).catch((error) => {
        console.log(error);
        this.isLoading = false;
        const error_ = errorProcess(null, null)
        this.alert_msg = error_.msg;
        this.alert_type = error_.type;
      })
    }
  },
  data () {
    return {
      email: '',
      password: '',
      alert_msg: null,
      alert_type: null,
      isLoading: false
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/variables";

@media (max-width: $wrapper-avg-width) {
  .GrtSignIn {
    padding: 0 $padding-small;
  }
}

.GrtSignIn {
  margin-top: $padding-big;

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 10% auto;

    &-title {}

    &-alerts {
      display: flex;
      justify-content: center;
      margin-bottom: $padding-small;

      &-loader {
      }

      &-msg {
        width: 100%;
        font-size: 16px;
        font-weight: 100;
      }
    }

    &-group {
      &-label {
        margin-bottom: 10px;
        font-weight: 100;
      }

      &-input {}

    }

    &-submit {
      margin: 20px auto 0;
      padding: 10px 40px;
      font-weight: 400;
    }

    &-reset {
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      margin-top: $padding-small;
    }

    &-options {
      margin-top: $padding-big;
    }

    &-signup {
      text-align: center;
      margin-top: 20px;
      font-weight: 100;
      text-decoration-line: underline;
      color: black;
      font-size: 18px;
    }
  }

}

</style>
