<template>
  <GrtMainWrapper>
  <div class="GrtSignUp">
    <form class="GrtSignUp__form" @submit.prevent="handleSubmit">
      <div class="page-title">Регистрация</div>
      <div class="form-group">
        <div class="GrtSignUp__form-alerts">
          <GrtAlert class="GrtSignUp__form-alerts-msg" :msg="this.alert_msg" :type="this.alert_type"/>
          <div class="GrtSignUp__form-alerts-loader spinner-grow text-primary" v-if="this.isLoading">
            <span class="sr-only">Регистрация...</span>
          </div>
        </div>
        <label class="GrtSignUp__form-group-label" for="emailInput">Email-адрес</label>
        <input id="emailInput" class="GrtSignUp__form-input form-control" type="email" placeholder="email" v-model="email" required>
        <small id="emailHelp" class="form-text text-muted">Email-адрес нужен для входа, и получения уведомлений</small>
      </div>
      <div class="form-group">
        <label class="GrtSignUp__form-group-label" for="passwordInput">Пароль</label>
        <div class="input-group">
          <input id="passwordInput" class="GrtSignUp__form-input form-control" type="password" placeholder="пароль" v-model="password" required>
          <div class="input-group-append">
            <button id="showPassword" class="btn btn-link" type="button" @mouseover="showPassword" @mouseout="hidePassword">
              <i class="bi bi-eye"/>
            </button>
          </div>
        </div>
      </div>
      <p class="GrtSignUp__form-info form-text">Регистрируясь, Вы соглашаетесь с
        <router-link :to="{name: 'GrtRules'}">
          <span class="link GrtSignUp__form-info">пользовательским соглашением</span>
        </router-link>
      </p>
      <button type="submit" class="btn btn-primary GrtSignUp__form-submit">Регистрация</button>
    </form>
  </div>
  </GrtMainWrapper>
</template>

<script>
import axios from "@/utils/axios";
import {errorProcess, USER_ALREADY_EXIST_ERROR} from "@/utils/errors";
import GrtAlert from "@/components/controls/GrtAllert";
import {SetCookie} from "@/utils/cookie";
import router from "@/router/router";
import {mapActions} from "vuex";
import GrtMainWrapper from "@/components/controls/GrtMainWrapper.vue";

export default {
  name: "GrtSignUp",
  components: {GrtMainWrapper, GrtAlert},
  data() {
    return {
      email: null,
      password: null,
      alert_msg: null,
      alert_type: null,
      isLoading: false
    }
  },
  methods: {
    ...mapActions([
      'UPDATE_AUTH_STATE'
    ]),
    async handleSubmit () {
      this.alert_msg = null;
      this.isLoading = true;

      await axios('auth.signUpEmail', {
        method: "POST",
        data: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        console.log("reg response", response)
        this.isLoading = false;
        const data = response.data;
        if (data.response) {
          const auth = data.response.auth;
          SetCookie(document,'auth_token', auth.token, auth.expire);
          this.UPDATE_AUTH_STATE(true);
          router.push('/space');
        } else {
          const error = errorProcess(
              data.error,
              [USER_ALREADY_EXIST_ERROR]
          )
          this.alert_msg = error.msg;
          this.alert_type = error.type;
        }
      }).catch((error) => {
        console.log(error);
        this.isLoading = false;
        const error_ = errorProcess(null, null)
        this.alert_msg = error_.msg;
        this.alert_type = error_.type;
      })
    },
    showPassword() {
      const passwordInput = document.getElementById("passwordInput")
      passwordInput.type = "text"
    },
    hidePassword() {
      const passwordInput = document.getElementById("passwordInput")
      passwordInput.type = "password"
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

@media (max-width: $wrapper-avg-width) {
  .GrtSignUp {
    padding: 0 $padding-small;
  }
}

.GrtSignUp {
  &__form {
     display: flex;
     flex-direction: column;
     max-width: 500px;
     margin: 10% auto;

    &-alerts {
      display: flex;
      justify-content: center;
      margin-bottom: $padding-small;

      &-loader {
      }

      &-msg {
        width: 100%;
        font-size: 16px;
        font-weight: 100;
      }
    }

    &-group {
      &-label {
        margin-bottom: 10px;
        font-weight: 100;
      }

      &-input {}

    }

    &-submit {
      padding: 10px 40px;
      margin: 20px auto 0;
     }

    &-info {
      font-size: 12px;
      text-align: right;
      font-weight: 100;

      &-link {
        font-size: 12px;
      }
    }
  }
}

</style>
