<template>
  <div class="GrtConfirmRecoveryPassword">
    <form class="GrtConfirmRecoveryPassword__form" @submit.prevent="handleSubmit">
      <div class="page-title">Восстановление пароля</div>
      <div class="GrtConfirmRecoveryPassword__form-alerts">
        <GrtAlert class="GrtConfirmRecoveryPassword__form-alerts-msg" :msg="this.alert_msg" :type="this.alert_type"/>
        <div class="GrtConfirmRecoveryPassword__form-alerts-loader spinner-grow text-primary" v-if="this.isLoading">
          <span class="sr-only">Регистрация...</span>
        </div>
      </div>
      <div class="form-group">
        <label for="passwordInput">Новый пароль</label>
        <input
            id="passwordInput"
            class="GrtConfirmRecoveryPassword__form-input form-control"
            type="password"
            placeholder="password"
            v-model="password"
            required>
        <small id="emailHelp" class="form-text text-muted">Введите новый пароль</small>
      </div>
      <button type="submit" class="btn btn-primary GrtConfirmRecoveryPassword__form-submit">Восстановить</button>
    </form>
  </div>
</template>

<script>
import axios from "@/utils/axios";
import {
  errorProcess, RECOVERY_PASSWORD_TOKEN_INCORRECT,
} from "@/utils/errors";
import router from "@/router/router";
import GrtAlert from "@/components/controls/GrtAllert";


export default {
  name: "GrtConfirmRecoveryPassword",
  data() {
    return {
      password: null,
      alert_msg: null,
      alert_type: null,
      isLoading: false
    }
  },
  components: {
    GrtAlert
  },
  methods: {
    async handleSubmit () {
      this.alert_msg = null;
      this.isLoading = true;

      const token = this.$route.query.cc

      if (!token) {
        router.push('/sign_in')
      }

      await axios('auth.confirmPasswordRecovery', {
        method: "POST",
        data: JSON.stringify({
          token: token,
          new_password: this.password,
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        this.isLoading = false;
        const data = response.data;

        if (data.response) {
          this.alert_msg = 'Пароль успешно изменен.'
          this.alert_type = 'info';

        } else {
          const error = errorProcess(
              data.error,
              [
                RECOVERY_PASSWORD_TOKEN_INCORRECT,
              ]
          )
          this.alert_msg = error.msg;
          this.alert_type = error.type;
        }
      }).catch((error) => {
        console.log(error);
        this.isLoading = false;
        const _error = errorProcess(null, null)
        this.alert_msg = _error.msg;
        this.alert_type = _error.type;
      })
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/styles/variables";

.GrtConfirmRecoveryPassword {
  &__form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 10% auto;

    &-alerts {
      display: flex;
      justify-content: center;
      margin-bottom: $padding-small;

      &-loader {
      }

      &-msg {
        width: 100%;
        font-size: 16px;
        font-weight: 100;
      }
    }

    &-submit {
      margin: 20px auto 0;
     }

    &-info {
      font-size: 14px;
      text-align: right;
    }
  }
}

  @media (max-width: $wrapper-avg-width) {
  .GrtConfirmRecoveryPassword {
    padding: 0 $padding-small;
  }
}

</style>
