import {createRouter, createWebHistory} from "vue-router"
import GrtSpace from "@/components/pages/GrtSpace";
import GrtIndex from "@/components/pages/GrtIndex";
import GrtSettings from "@/components/pages/GrtSettings";
import GrtGuaranteeNew from "@/components/pages/GrtGuaranteeNew";
import GrtGuaranteeEdit from "@/components/pages/GrtGuaranteeEdit";
import GrtSignIn from "@/components/pages/GrtSignIn";
import GrtSignUp from "@/components/pages/GrtSignUp";
import GrtLogout from "@/components/pages/GrtLogout";
import GrtRules from "@/components/pages/GrtRules";
import GrtRecoveryPassword from "@/components/pages/GrtRecoveryPassword";
import GrtResendConfirmEmail from "@/components/pages/GrtResendConfirmEmail";
import GrtConfirmEmail from "@/components/pages/GrtConfirmEmail";
import GrtConfirmRecoveryPassword from "@/components/pages/GrtConfirmRecoveryPassword";
import GrtCategoriesSettings from "@/components/pages/GrtCategoriesSettings.vue";

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'GrtIndex',
            component: GrtIndex,
        },
        {
            path: '/space',
            name: 'GrtSpace',
            component: GrtSpace,
        },
        {
            path: '/settings',
            name: 'GrtSettings',
            component: GrtSettings,
        },
        {
            path: '/settings/categories',
            name: 'GrtCategoriesSettings',
            component: GrtCategoriesSettings,
        },
        {
            path: '/new',
            name: 'GrtGuaranteeNew',
            component: GrtGuaranteeNew,
        },
        {
            path: '/edit/:guarantee_id',
            name: 'GrtGuaranteeEdit',
            component: GrtGuaranteeEdit,
            props: true
        },
        {
            path: '/sign_in',
            name: 'GrtSignIn',
            component: GrtSignIn,
        },
        {
            path: '/sign_up',
            name: 'GrtSignUp',
            component: GrtSignUp,
        },
        {
            path: '/logout',
            name: 'GrtLogout',
            component: GrtLogout,
        },
        {
            path: '/rules',
            name: 'GrtRules',
            component: GrtRules,
        },
        {
            path: '/recovery_password',
            name: 'GrtRecoveryPassword',
            component: GrtRecoveryPassword,
        },
        {
            path: '/confirm_recovery_password',
            name: 'GrtConfirmRecoveryPassword',
            component: GrtConfirmRecoveryPassword,
        },
        {
            path: '/resend_email_confirm',
            name: 'GrtResendConfirmEmail',
            component: GrtResendConfirmEmail,
        },
        {
            path: '/email_confirm',
            name: 'GrtConfirmEmail',
            component: GrtConfirmEmail,
        },
   ]
});
